import React from 'react';
import css from "./style.scss";
import classNames from "classnames";
import { ReactSVG } from 'react-svg';
import downFullArrow from '../../../assets/images/account-page/down-full-arrow.svg'
import downArrow from '../../../assets/images/account-page/down-arrow.svg'
import visaCard from '../../../assets/images/account-page/visa-card.svg'
import planIcons1 from '../../../assets/images/account-page/plan-icons-1.svg'
import planIcons2 from '../../../assets/images/account-page/plan-icons-2.svg'

const ReviewAndSubmit = (props) => {
  return (
    <div className={css.clsInnerMainBody}>
      <div className={css.clsInnerPage}>
        <div className="container">
          <div className={css.clsPlanReviewPaySec}>
            <div className={css.clsPlansCardsGrp}>
              <div className={css.clsPlanReviewPayCard1}>
                <div className={css.clsPlanBtnGrp}>
                  <button className={css.clsPlanBtn}>OLD PLAN</button>
                </div>
                <div className={css.clsPlanDetails}>
                  <div className={css.clsPlanDetailsGrp1}>
                    <div className={css.clsPlanICons}>
                      <ReactSVG src={planIcons1} />
                    </div>
                    <h4 className={css.clsPlanHeading}>Essentials</h4>
                  </div>

                  <div className={css.clsPlanDetailsGrp2}>
                    <div className="clearfix">
                      <h4 className={css.clsTxtLine1}>TYPE</h4>
                      <h4 className={css.clsTxtLine2}>Monthly</h4>
                    </div>
                    <span className={css.clsBorder}></span>
                    <div className="clearfix">
                      <h4 className={css.clsTxtLine1}>SAVES & EXPORTS</h4>
                      <h4 className={css.clsTxtLine2}>25,000<sub>/mo</sub></h4>
                    </div>
                  </div>
                </div>

                <div className={css.clsPlanIncludedSec}>
                  <ul className={css.clsPlansList}>
                    <li className={css.clsPlansItem}>
                      <h3 className={css.clsPlansHeading}>Included</h3>
                    </li>
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle, css.clsPlansCircleNon)}></span>
                      <h5 className={css.clsPlansListName}>None</h5>
                    </li>
                  </ul>
                </div>

                <div className={css.clsPlanAddedOnSec}>
                  <ul className={css.clsPlansList}>
                    <li className={css.clsPlansItem}>
                      <h3 className={css.clsPlansHeading}>Added-On</h3>
                    </li>
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle)}></span>
                      <h5 className={css.clsPlansListName}>Lead Automator 50k</h5>
                    </li>
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle)}></span>
                      <h5 className={css.clsPlansListName}>2 Full Access Team Members</h5>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text-center">
                <ReactSVG src={downFullArrow} />
              </div>
              <div className={css.clsPlanReviewPayCard1}>
                <div className={css.clsPlanBtnGrp}>
                  <button className={classNames(css.clsPlanBtn, css.clsBtnOrng)}>NEW PLAN</button>
                </div>
                <div className={css.clsPlanDetails}>
                  <div className={css.clsPlanDetailsGrp1}>
                    <div className={css.clsPlanICons}>
                      <ReactSVG src={planIcons2} />
                    </div>
                    <h4 className={css.clsPlanHeading}>Pro</h4>
                  </div>

                  <div className={css.clsPlanDetailsGrp2}>
                    <div className="clearfix">
                      <h4 className={css.clsTxtLine1}>TYPE</h4>
                      <h4 className={css.clsTxtLine2}>Monthly</h4>
                    </div>
                    <span className={css.clsBorder}></span>
                    <div className="clearfix">
                      <h4 className={css.clsTxtLine1}>SAVES & EXPORTS</h4>
                      <h4 className={css.clsTxtLine2}>25,000<sub>/mo</sub></h4>
                    </div>
                  </div>
                </div>

                <div className={css.clsPlanIncludedSec}>
                  <ul className={css.clsPlansList}>
                    <li className={css.clsPlansItem}>
                      <h3 className={css.clsPlansHeading}>Included</h3>
                    </li>
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle)}></span>
                      <h5 className={css.clsPlansListName}>Lead Automator 50k</h5>
                    </li>
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle)}></span>
                      <h5 className={css.clsPlansListName}>2 Full Access Team Members</h5>
                    </li>
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle)}></span>
                      <h5 className={css.clsPlansListName}>1,000 Monthly Skip Trace Credits</h5>
                    </li>
                  </ul>
                </div>

                <div className={css.clsPlanAddedOnSec}>
                  <ul className={css.clsPlansList}>
                    <li className={css.clsPlansItem}>
                      <h3 className={css.clsPlansHeading}>Added-On</h3>
                    </li> 
                    <li className={css.clsPlansItem}>
                      <span className={classNames(css.clsPlansCircle, css.clsPlansCircleNon)}></span>
                      <h5 className={css.clsPlansListName}>None</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={css.clsPlanReviewPayCard2}>
              <h3 className={css.clsPlanReviewHeading}>Review And Confirm Changes</h3>
              <div className={css.clsPlanReviewTimelineContainer}>
                <div className={css.clsPlanReviewTimeline}>
                  <div className={css.clsPlanTimelineItem}>
                    <div className={css.clsPlanTimelineDot}></div>
                    <div className={css.clsPlanTimelineContent}>
                      <div className={css.clsPlanTimelineHead}>
                        <h4 className={css.clsPlanTimelineLabel}>Due Today</h4>
                        <h5 className={css.clsPlanTimelinePrice}>$7</h5>
                      </div>
                      <p className={css.clsPlanTimelineDescription}>Team Member gains full access, 7 days until next billing cycle.</p>
                    </div>
                  </div>

                  <div className={css.clsPlanTimelineLine}></div>

                  <div className={css.clsPlanTimelineItem}>
                    <div className={css.clsPlanTimelineDot}></div>
                    <div className={css.clsPlanTimelineContent}>
                      <div className={css.clsPlanTimelineHead}>
                        <h4 className={css.clsPlanTimelineLabel}>Starting June 17, 2024</h4>
                        <h5 className={css.clsPlanTimelinePrice}>$30/month</h5>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <div className={css.pb_48}>
                <div className={css.clsPlanReviewPMCard}>
                  <h4 className={css.clsPlanReviewPMCardHeading}>Payment Method</h4>

                  <div className={css.clsDropdownCard}>
                    <div className={classNames(css.clsPayCard, css.clsPayCardFull, css.align_items_center, css.justify_content_between, "flex-fill")}>
                      <div className={classNames(css.d_flex, css.align_items_center)}>
                        <img src={visaCard} className="mr-3" alt="" />
                        <div className="clearfix">
                          <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                          <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                        </div>
                      </div>
                    </div>
                    <a className={css.pl_16} href="javascript:;">
                      <img src={downArrow} alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className={css.clsPlanReviewContentPara}>
                <p className={classNames(css.clsPara, css.mb_16)}>
                  <span className={css.text_b}>Starting on June 17, 2024 you’ll be charged $30.00 automatically every month until you cancel.</span> This does not include any other PropStream services you may be subscribed to. Your price may change as described in the <span className={css.text_primary}>PropStream Terms and Conditions.</span>
                </p>
                <p className={css.clsPara}>
                  By clicking “Confirm Changes” you agree to the <span className={css.text_primary}>PropStream Terms and Conditions.</span>
                </p>
              </div>

              <div className={classNames(css.pb_48, "d-flex justify-content-center")}>
                <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)}> Confirm Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={css.clsInnerFooter}>
        <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)}> Back to Tiers</button>
        {/* <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)}> Continue</button> */}
      </div>
    </div>
  )
}

export default ReviewAndSubmit;
