import leadlgIcon from 'assets/images/account-page/lead-auto-lg-img.svg';
import classNames from "classnames";
import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';



const LeadAutomatorScreen = (props) => {


  const [selectedPlan, setSelectedPlan] = useState(120000); // Default selected plan

  const plans = [
    { id: 50000, properties: "50,000", price: "$27", tag: "Current Plan" },
    { id: 120000, properties: "120,000", price: "$47" },
    { id: 250000, properties: "250,000", price: "$97" },
    { id: 600000, properties: "600,000", price: "$297" },
    { id: 1200000, properties: "1,200,000", price: "$497" },
  ];


  const goToManageleadAutomator = () => {
    props.history.push(`/accountnew/manageleadautomator`);
  }

  const goToManageLeadAutomator = (type) => {
    props.history.push(`/accountnew/manageleadautomator/${type}`);
  };

  const goToAccount = () => {
    props.history.push(`/accountnew/landing`);
  }
  

  return (
    <>
      <div className={css.clsInnerMainBody}>
        <div className={classNames("d-flex align-items-center")}>
          <div className="container">
            <div className={css.clsLeamAutoMainSec}>
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className={css.clsLAMainLeft}>
                    <h2 className={css.clsLAMainHeading}>Lead Automator</h2>
                    <div className={css.clsLAImgLarge}>
                      <img src={leadlgIcon} alt="leadlgIcon" />
                    </div>
                    <p className={css.clsLAMainPara}>
                      Lorem ipsum dolor sit amet consectetur. Odio viverra habitasse in eget vel. Tortor sollicitudin ultrices tellus odio. Odio gravida consequat montes sed faucibus euismod tristique pellentesque sit. Aliquam ultricies lacus sit enim maecenas sagittis aliquet viverra. Dis viverra orci auctor diam dolor. Fames egestas fringilla at eu tristique quis malesuada.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className={css.clsLAMainRight}>
                    <ul className={css.clsLASubscribeList}>

                      {plans.map((plan) => (
                        <li
                          key={plan.id}
                          className={`${css.clsLASubscribeItem}`}
                          onClick={() => setSelectedPlan(plan.id)}
                        >
                          <a href="javascript:;"
                            className={`${css.clsLASubscribeLink} ${ 
                              selectedPlan === plan.id ? css.selected : ""}`}>
                            <div className={css.clsLASubscribeLeftTxt}>
                              <h4 className={css.clsLASubscribeTxt1}>{plan.properties}</h4>
                              <p className={css.clsLASubscribeTxt2}>Monitored Properties</p>
                            </div>
                            {plan.tag && <div className={css.clsLASubscribeBtn}>
                              <button className={classNames(css.btn_primary, css.clsBtnPrimaryGreen)}> {plan.tag}</button>
                            </div>}

                            <div className={css.clsLASubscribeRightTxt}>
                              <h4 className={css.clsLASubscribeTxt1}>{plan.price}<sub>/mo</sub></h4>
                            </div>
                          </a>


                        </li>
                      ))}


                    </ul>

                    <div className="d-flex justify-content-center">
                      <p className={css.clsLAUnSubscribeTxt}  onClick={() => goToManageLeadAutomator('unsubscribe')}>Unsubscribe from Lead Automator</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.clsInnerFooter}>
          <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnPrimaryGreen)} onClick={() => goToAccount()}> Cancel</button>
          <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)} onClick={() => goToManageLeadAutomator('review')}> Continue</button>
        </div>
      </div>


    </>
  );
};


export default withRouter(LeadAutomatorScreen);
